import envs from './env-list'
import packageJson from '../../../package.json'

const API_PROTOCOL = `${import.meta.env.VITE_API_PROTOCOL || 'https'}`
const currentEnv = getCurrentEnv()

export default {
  name: currentEnv.name,
  env: currentEnv.env,

  portal: {
    version: packageJson.version,
    baseURL: import.meta.env.BASE_URL,
    client: import.meta.env.VITE_CLIENT_ENV || currentEnv.client
  },

  api: {
    authSystem: import.meta.env.VITE_API_AUTH_SYSTEM || currentEnv.authSystem,
    baseURL: `${API_PROTOCOL}://${import.meta.env.VITE_API_HOST ?? currentEnv.api}`,
    userApiBaseUrl: `${API_PROTOCOL}://${import.meta.env.VITE_USER_API_HOST ?? currentEnv.userApi}`,
    ocppPocApiBaseUrl: `${API_PROTOCOL}://${import.meta.env.VITE_OCPP_API_HOST ?? currentEnv.ocppPocApi}`,
    perseusApiBaseUrl: `${API_PROTOCOL}://${import.meta.env.VITE_PERSEUS_API_HOST ?? currentEnv.perseusApi}`,
    timezoneApi: `${API_PROTOCOL}://${import.meta.env.VITE_TIMEZONE_API_HOST ?? currentEnv.timezoneApi}`
  },

  mixpanel: {
    token: import.meta.env.VITE_MIXPANEL_TOKEN || currentEnv.mixpanel?.token,
    active: import.meta.env.VITE_MIXPANEL_ACTIVATE === 'true' || currentEnv.mixpanel?.active
  },

  featureFlags: {
    baseURL: import.meta.env.VITE_FEATURE_FLAG_URL || currentEnv.featureFlags?.baseURL,
    active: import.meta.env.VITE_FEATURE_FLAG_ACTIVATE === 'true' || currentEnv.featureFlags?.active
  },

  oauth: {
    google: import.meta.env.GOOGLE_CLIENT_ID || currentEnv.oauth?.google,
    apple: import.meta.env.APPLE_CLIENT_ID || currentEnv.oauth?.apple
  },

  google: {
    maps: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
  },

  pwa: {
    active: currentEnv.pwa?.active
  },

  deepLinks: {
    publicCharge: currentEnv.deepLinks.publicCharge
  },

  mavenoid: {
    clientId: currentEnv.mavenoid.clientId,
    productId: currentEnv.mavenoid.productId
  }
}

function getCurrentEnv () {
  let selectedEnv = envs.default

  const keys = Object.keys(envs)
  keys.forEach(env => {
    if (envs[env].web.includes(document.location.host)) {
      selectedEnv = envs[env]
    }
  })

  return selectedEnv
}

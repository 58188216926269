import clientInit from '@/engine/clients'
import i18n from '@/engine/lang'
import initRouter from '@/engine/router'
import initVendors from '@/engine/vendors'
import featureFlagsInit from '@/engine/featureFlags'
import initPwa from '@/engine/pwa'
import initInjects from './injectors'
import ENV from '@/engine/env/web.env'
import { initClientTrackers } from '@wallbox/metrics'
import { loadStates } from '@/state'
import type { App } from 'vue'

export default async function initApp (app: App) {
  initPwa()
  await featureFlagsInit()
  await clientInit()

  initVendors(app, i18n)
  initClientTrackers({
    datadog: {} as any,
    mixpanel: {
      active: ENV.mixpanel.active,
      token: ENV.mixpanel.token ?? ''
    }
  })
  loadStates()
  initInjects(app)

  const router = initRouter()

  return { i18n, router }
}


export enum CHARGER_TYPES {
  PULSAR = 'pulsar',
  PULSAR_PLUS = 'pulsarplus',
  PULSAR_MAX = 'pulsar-max',
  PULSAR_MAX_SOCKET = 'pulsar-max-socket',
  PULSAR_MAX_ABL = 'pulsar-max-abl',
  PULSAR_PRO = 'pulsar-pro',
  PULSAR_PRO_SOCKET = 'pulsar-pro-socket',
  PULSAR_PRO_ABL = 'pulsar-pro-abl',
  PULSAR_PRO_SOCKET_ABL = 'pulsar-pro-socket-abl',
  COMMANDER = 'commander',
  COMMANDER_2 = 'commander-2',
  COPPER = 'copper',
  QUASAR = 'quasar',
  SUPERNOVA = 'supernova'
}

export enum FEATURES {
  POWER_BOOST,
  POWER_SHARING,
  DYNAMIC_POWER_SHARING,
  ECO_SMART
}

export interface ChargerType {
  name: string
  code: CHARGER_TYPES
  chargers: string[]
  actions?: {
    minVersion: string
  }
  features: FEATURES[]
  payments?: {
    ppm: {
      minVersion: string
    }
    ppc: {
      minVersion: string
    }
  }

  gunLock?: {
    minVersion: string
  }
}
